<template>
    <tr>
        <td style="text-align:center">            
            <v-select
                v-model="itemModel.subject_id"                
                :items="subjects"
                item-value="id"
                outlined
                dense
                hide-details
                @change="updateRecord"
                :disabled="!admin" 
            >
                <template 
                    v-slot:selection="{item}"                                       
                >
                   <span style="font-size:0.8rem; width:40px; text-align:center">{{ item.id }}</span>
                </template>

                <template
                    v-slot:item="data"
                > 
                    {{ data.item.id}} {{ data.item.title }}
                </template>
            </v-select>
        </td>

        <td>{{ itemModel.abbr}}</td>

        <td 
            style="text-align:center"
        >
            <v-text-field
                v-model="itemModel.course_mark"
                hide-details                
                type="number"
                outlined
                style="font-size:0.8rem"
                @blur="updateRecord('course_mark')"
                @keypress="numberValidation" 
                :disabled="!admin ||  disableCourseMark"
                :filled="disableCourseMark"  
            ></v-text-field>
        </td> 

        <td 
            style="text-align:center"                       
        >
            <v-text-field
                v-model="itemModel.exam_mark"
                hide-details                
                type="number"
                outlined
                style="font-size:0.8rem"
                @keypress="numberValidation" 
                @blur="updateRecord('exam_mark')"
                :disabled="!admin || disableExamMark"
                :filled="disableExamMark"                 
            ></v-text-field>
        </td> 

        <td>            
            <v-select
                v-model="itemModel.coded_comment"                
                :items="codedComments1"
                item-value="id"
                outlined
                dense
                hide-details
                @change="updateRecord"
                :disabled="!admin"  
            >
                <template 
                    v-slot:selection="{item}"                                       
                >
                   <span style="font-size:0.8rem; width:40px; text-align:center">{{ item.id }}</span>
                </template>

                <template
                    v-slot:item="data"
                > 
                    {{ data.item.id}} {{ data.item.detail }}
                </template>
            </v-select>                                   
        </td>

        <td>
            <v-select
                v-model="itemModel.coded_comment_1"                
                :items="codedComments1"
                item-value="id"
                outlined
                dense
                hide-details
                @change="updateRecord"
                :disabled="!admin"  
            >
                <template 
                    v-slot:selection="{item}"                                       
                >
                   <span style="font-size:0.8rem; width:40px; text-align:center">{{ item.id }}</span>
                </template>

                <template
                    v-slot:item="data"
                > 
                    {{ data.item.id}} {{ data.item.detail }}
                </template>
            </v-select>                               
        </td> 
        
        <td>
            <v-btn 
                icon
                @click="deleteRecord"
                :disabled="!admin" 
            >
                <v-icon
                    small
                >
                    mdi-delete
                </v-icon>
            </v-btn>
        </td>        
    </tr>    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    props: {
        item: Object,
        formLevel: Number,
        paper3: Boolean,              
    },

    watch: {
        item: {
            handler () {
                this.initialize();
            }
        }
    },
    
    mounted: function () {        
        this.initialize();       
    },

    data: () => ({
        subject: null,
        codedComments1: [],
        codedComments2: [],
        display: false,
        itemModel: {},
        disableExamMark: false,
        disableCourseMark: false,
    }),

    computed: {
        ...mapGetters({
            subjects: 'admin/getSubjects',
            codedComments: 'termReports/getCodedComments',
            admin: 'auth/getAdmin',            
        }),            
      
    },    

    methods: {
        ...mapActions({
            postTable2Record: 'admin/postTable2',
            getTable2Records: 'termReports/getTable2Records',
        }),

        ...mapMutations({
            setTable2Record: 'admin/setSelectedTable2Record',           
            setTable2Records: 'termReports/setEditViewTermReportsTable2Records',
            setStudentSubjects: 'termReports/setEditViewTermReportsStudentSubjects',
            setSnackbar: 'admin/setSnackbar',
            setDialogDelete: 'admin/setDialogDelete',
            setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
            setSaved: 'termReports/setEditViewTermReportsSaved',
            setOverlayError: 'enterMarks/setOverlayError',
            setError: 'enterMarks/setError',
        }),

        initialize () {
            // console.log('initializing...');
            this.codedComments1 = [...this.codedComments];
            this.codedComments2 = [...this.codedComments];
            
            this.display = false;
            this.disableExamMark = false;
            this.disableCourseMark = false;

            if(this.item.term == 2 && this.formLevel < 5){
                this.display = true;
            }

            if(this.item.term == 3 && this.formLevel == 3){
                this.disableExamMark = true;
                // console.log("1");
            }           

            if(this.formLevel > 4){
                this.disableCourseMark = true;
                // console.log("2");
            }
            this.itemModel = { ...this.item };
            // console.log(`Disable Course Mark: ${this.disableCourseMark}`);
            // console.log(`Disable Exam Mark: ${this.disableExamMark}`);
        },

        updateRecord (mark = null) {
            if(mark && this.markValid(mark) && this.recordChangeCheck()){
                this.postRecord();
            }
            // console.log(`Record change: ${this.recordChangeCheck()}`);
        },

        recordChangeCheck () {
            console.log(this.item);
            let change = false;
            Object.keys(this.item).forEach(key => {
                if(
                    key == 'course_mark' ||
                    key == 'exam_mark'                    
                ){
                    if(this.item[key] != this.itemModel[key]){
                        change = true;
                    }
                }
            })
            if(change) return true;
            return false;
        },

        async postRecord () {
            this.setSaving(true);
            this.setSaveStatus("Updating Record");
            this.setSaved(false);
            this.setSaveError(false);

            let table2Record = Object.assign({}, this.itemModel);
            table2Record.subject_id_old = this.item.subject_id;
            table2Record.subject_id_new = this.itemModel.subject_id;
            this.setTable2Record(table2Record);
            try {
                let response = await this.postTable2Record();
                // console.log(response);

                response = await this.getTable2Records();
                this.setTable2Records(response.data.table2_records);
                this.setStudentSubjects(response.data.student_subjects);

                this.setSaving(false);
                this.setSaveStatus("Record Updated");
                this.setSaved(true);
                this.setSaveError(false);

                this.setSnackbar({
                    text: `Record Updated`,
                    display: true,
                    color: 'primary',
                }); 

            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSnackbar({
                    text: `Error occured`,
                    display: true,
                    color: 'red',
                });
                
                this.setSaving(false);
                this.setSaveStatus("Error Occured!");
                this.setSaved(false);
                this.setSaveError(true);
            }

        },

        markValid (model) {
            // console.log(model);
            // console.log(this.itemModel);
            let value = this.itemModel[model];
            // console.log(value);
            if(
                value && 
                model == 'course_mark' &&
                this.itemModel.term == 3 && 
                this.formLevel == 3 &&                
                value > 100 
            ){
                console.log('1')
                this.itemModel[model] = null;
                this.setOverlayError(true);
                this.setError({
                    response: 'Course Mark Cannot be greater than 100.'
                })
                return false;
            }
            else if(
                value && 
                model == 'course_mark' && 
                this.itemModel.term == 3 && 
                this.formLevel < 3 && 
                value > 30 
            ){
                console.log('2')
                this.itemModel[model] = null;
                this.setOverlayError(true);
                this.setError({
                    response: 'Course Mark Cannot be greater than 30.'
                })
                return false;
            }
            else if(
                value && 
                model == 'course_mark' && 
                this.itemModel.term != 3 && 
                this.formLevel < 4 && 
                value > 30 
            ){
                console.log('3')
                this.itemModel[model] = null;
                this.setOverlayError(true);
                this.setError({
                    response: 'Course Mark Cannot be greater than 30.'
                })
                return false;
            }
            else if(
                value && 
                model == 'course_mark' && 
                this.formLevel == 4 &&
                value > 20
                              
            ){
                console.log('4')
                this.itemModel[model] = null;
                this.setOverlayError(true);
                this.setError({
                    response: 'Course Mark Cannot be greater than 20.'
                })
                return false;
            }
            else if(
                value && 
                model == 'exam_mark' && 
                this.formLevel < 4 &&
                value > 70
                
            ){
                console.log('5')
                this.itemModel[model] = null;
                this.setOverlayError(true);
                this.setError({
                    response: 'Exam Mark Cannot be greater than 70.'
                })
                return false;
            }
            else if(
                value && 
                model == 'exam_mark' && 
                value > 80 &&
                this.formLevel == 4
            ){
                console.log('6')
                this.itemModel[model] = null;
                this.setOverlayError(true);
                this.setError({
                    response: 'Exam Mark Cannot be greater than 80.'
                })
                return false;
            }    
            else if(value && model == 'exam_mark' && value > 100){
                console.log('7')
                this.itemModel[model] = null;
                this.setOverlayError(true);
                this.setError({
                    response: 'Exam Mark Cannot be greater than 100.'
                })
                return false;
            } 
            console.log('8')        
            return true;
        },

        deleteRecord () {                      
            this.setDialogDelete({
                display: true,
                text: `Delete ${this.itemModel.title} ?`,
                cancel: true,
                deleteSubject: true,
                deleteStudent: false,
                ok: false
            })            
            this.setTable2Record(this.item);            
        },

        numberValidation ($event) {
            if($event.keyCode < 48 || $event.keyCode > 57)
            return $event.preventDefault();
        },
        
    }
}
</script>

<style scoped>
    ::v-deep .v-select__slot{
        height: 20px;
    }

    ::v-deep .v-input__icon{
        height: 5px;
        min-width: 8px;
        width: 8px;
    }

    ::v-deep .v-select.v-text-field--outlined:not(.v-text-field--single-line).v-input--dense .v-select__selections{
        height: 20px;
        padding: 0px;
    }

    ::v-deep .v-input input{
        max-height: 20px;
    }

    ::v-deep .v-btn--icon.v-size--default{
        height: 20px;
        width: 20px;
    }

    /* Chrome, Safari, Edge, Opera */
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    ::v-deep input[type=number] {
    -moz-appearance: textfield;
    }

    
</style>