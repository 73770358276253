<template>
    <layout>       

        <template v-slot:username>
            <v-card-text>                                                
                <div class="primary--text text-h5 text-center">
                    Term Reports Sign in                                                            
                </div>                                            
            </v-card-text>
            <v-form 
                class="pa-5"
                @submit.prevent
            >
                <v-text-field
                label="Username"
                placeholder="Enter username"
                name="id"                                                
                type="text"
                :color="icon_color"
                v-model="formData.name"
                clearable
                autofocus
                v-on:keyup="keyPress"
                >
                    <v-icon
                        slot="prepend"
                        :color="icon_color"
                    >
                        mdi-account
                    </v-icon>
                </v-text-field>
                <div class="text-right mt-4 mb-4">                                                
                    <v-btn 
                        color="primary" 
                        @click="setStep(2)" 
                        dark
                        block
                    >
                        NEXT
                    </v-btn>
                </div>
            </v-form>
        </template>

        <template v-slot:password>
            <v-form 
                class="pa-5"
                @submit.prevent="submit"
            >
                <v-text-field
                    v-show="password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    :label="passwordLabel"
                    :placeholder="passwordPlaceholder"
                    :hint="passwordHint"
                    persistent-hint
                    @click:append="show = !show"                                                    
                    name="password"
                    v-model="formData.password"
                    clearable
                    autofocus
                >
                    <v-icon
                        slot="prepend"
                        :color="icon_color"
                    >
                        mdi-key
                    </v-icon>
                </v-text-field>
            

                <div class="text-right mt-4  mb-4">
                    <v-btn 
                        color="primary" 
                        @click="submit" 
                        dark
                        block
                    >
                        LOGIN
                    </v-btn>
                </div>
            </v-form>             
        </template>

    </layout>
</template>

<script>
import Layout from '../layouts/Login';
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
    name: 'LoginTermReports',
    components: {
        Layout
    },
    data: () => ({
        formData: {
            name: '',
            password: '',            
        },
        show: false,        
        passwordPlaceholder: 'Enter password',
        passwordLabel: 'Password',
        passwordHint: '',
        password: true,
    }),
    computed: {
        ...mapGetters({            
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
            step: 'auth/getStep',
            authenticated: 'auth/getAuthenticated',
            resetPassword: 'auth/getResetPassword',
            admin: 'auth/getAdmin',                        
        }),        
        icon_color(){
             return 'primary';
        },
        title_css(){
            return  'primary--text text-h5 text-left ';
        },
    },
    methods: {
        ...mapActions({
            signIn: 'auth/employeeSignIn', 
            employee: 'auth/employee',
            getUser: 'auth/user',          
        }),
        ...mapMutations({
            loginError: 'auth/setLoginErrors', 
            setStep: 'auth/setStep',
            setOverlay: 'auth/setOverlay',
            setUserName: 'auth/setUserName',
            // setResetPassword: 'auth/setResetPassword',
            setExpand: 'auth/setExpand', 
            setAdmin: 'auth/setAdmin',
            setAuthenticated: 'auth/setAuthenticated',         
        }),

        async submit () {            
            console.log('Authenticating...');
            await this.signIn(this.formData);            
            if(this.authenticated){
                if(this.resetPassword && !this.admin){
                    setTimeout(() => {this.setOverlay(false)});
                    this.setAuthenticated(false);
                    sessionStorage.setItem('authenticated', '');
                    this.setStep(3);
                }
                else{
                    this.setExpand(false);
                    await this.employee();                    
                    setTimeout(() => {this.setOverlay(false)})
                    //this.setOverlay(false);
                    this.setAdmin(false);
                    this.$router.replace('/term-reports');
                }
                
            }
            else{
                console.log('Not authenticated');
            }

        },
       
        keyPress (e) {
            if(e.keyCode === 13){
                this.setStep(2);
            }
        },
        
    },
}
</script>