<template>
    <v-col class="pr-0 pb-0">
        <v-row class="mx-3">
            <v-card
                flat
                width="334"
                align="center"
                justify="center"
                class="mb-0"
                tile
            >
                <span 
                    class="mr-3 text-subtitle-1 green--text"                                         
                > {{ studentName }}</span>                                 
                <span 
                    class="text-subtitle-1 green--text"
                >{{ classId }}</span>
            </v-card>
        </v-row>

        <v-row class="mx-3">
            <v-card                                    
                width="334"
                flat
                tile                                    
            >
                <v-row
                    class="px-3"
                >
                    <v-col
                        align="center"
                        justify="center"
                        v-for="item in academicPeriod"
                        :key="item.title"
                        class="pa-0"
                    >
                        <div 
                            class="font-weight-bold primary--text"
                            style="font-size:0.7rem"
                        >
                            {{item.title}}
                        </div>
                        <div 
                            class=" secondary--text"
                            style="font-size:0.8rem"
                        >
                            {{item.value}}
                        </div>
                    </v-col>
                </v-row>
            </v-card>                                 
        </v-row>

        <v-row class="mx-3 mt-1">

            <v-card
                v-for="item in attendance"
                :key="item.title"
                flat
                width="167"
            >
                <v-card-text
                    class="py-0 primary--text font-weight-bold"
                    style="font-size:0.7rem; text-align:center"
                >{{ item.title }}</v-card-text>
                <v-card-text
                    class="py-0"
                >
                    <v-text-field
                        v-if="!item.select"
                        :disabled = "item.disabled"
                        :filled = "item.disabled" 
                        @blur="updateRecord"
                        dense
                        hide-details
                        v-model="studentRecord[item.model]"
                        height="25"
                        class="text-caption pa-0"
                        outlined
                    ></v-text-field>
                </v-card-text>
            </v-card>            
        </v-row>

        <v-row class="mx-3 mt-2">
            <v-card
                flat 
                width="334"
            >
                <v-card-text 
                    class="pa-0 mb-1 font-weight-bold primary--text"
                    style="font-size:0.7rem"
                >
                    Form Teacher's Remarks
                </v-card-text>
                <v-form ref="form">
                    <v-textarea                                       
                        label=""                            
                        persistent-hint
                        dense
                        rows="2"
                        :value="value"                        
                        counter                                            
                        class="text-caption"
                        :rules="rules"                      
                        no-resize
                        maxlength="215"
                        v-model="studentRecord.comments"                            
                        @blur="saveTeacherComment"
                        @click="openBottomSheet('comments')"
                        :readonly="disabled"
                        :filled="disabled"
                        outlined
                    >                        
                    </v-textarea>
                </v-form>
            </v-card>
        </v-row>

        <v-row class="mx-3">
            <v-card                
                width="334"
                flat
            >
                <v-card-text 
                    class="pa-0 mb-1 font-weight-bold primary--text"
                    style="font-size:0.7rem"
                >
                    Co-Curricular Activities
                </v-card-text>
                <div>
                    <v-list dense class="pt-0">
                        <v-list-item
                            v-for="value in coCurricular"
                            :key="value.title"
                            class="px-0"
                        >
                            <v-list-item-content class="pt-0 pb-0">
                                <v-list-item-title 
                                    v-text="value.title"
                                    class="text-caption"
                                ></v-list-item-title>
                            </v-list-item-content>
                            
                            <v-card
                                width="250"                                                                                                       
                                flat
                                class="pb-0"
                            >
                                <!-- <v-text-field
                                    v-if="!item.select"
                                    :disabled = "item.disabled"
                                    :filled = "item.disabled" 
                                    @blur="updateRecord"
                                    dense
                                    hide-details
                                    v-model="studentRecord[item.model]"
                                    height="25"
                                    class="text-caption pa-0"
                                    outlined
                                ></v-text-field> -->

                                <v-select
                                    v-if="value.select"
                                    :disabled = "value.disabled"
                                    :filled = "value.disabled"
                                    :items="activities" 
                                    v-model="studentRecord[value.model]"
                                    hide-details                                    
                                    outlined
                                    class="text-caption text-center"
                                    @change="updateRecord"
                                    small-chips                                   
                                >
                                    <template v-slot:selection="{ item }">
                                        <v-chip                                
                                            close
                                            @click:close="remove(value.model)"
                                            label
                                            small
                                        >
                                            <span >
                                                {{ item }}
                                            </span>
                                                                            
                                        </v-chip>
                                    </template>
                                </v-select>
                            </v-card>
                        </v-list-item>
                    </v-list>
                </div>                                     
            </v-card>
        </v-row>

        <v-row class="mx-3">
            <v-card                
                width="334"
                flat
                class="d-flex"
            >
                <v-card 
                    width="80"
                    flat
                    style="font-size:0.8rem"
                    class="pt-3"
                >
                    Moral Edu. / Ethics
                </v-card>
                <v-card
                    v-for="value in ethics"
                    :key="value.title"
                    width="85"
                    flat
                >
                    <v-card-text
                        class="pa-0 primary--text font-weight-bold"
                        style="text-align:center; font-size: 0.7rem"
                    >
                        {{ value.title }}
                    </v-card-text>
                    
                    <v-text-field
                        v-if="value.text"
                        v-model="studentRecord[value.model]"
                        outlined
                        hide-details
                        class="mr-2"
                        @blur="updateRecord"
                        :disabled = "value.disabled"
                        :filled = "value.disabled"  
                    ></v-text-field>

                    <v-select
                        v-else
                        hide-details
                        v-model="studentRecord[value.model]"
                        @change="updateRecord"
                        :disabled = "value.disabled"
                        :filled = "value.disabled"
                        :items="codedComments"
                        item-value="id"  
                        outlined
                        small-chips
                        deletable-chips
                    >
                        <template v-slot:selection="{ item }">
                            <v-chip                                
                                close
                                @click:close="remove(value.model)"
                                label
                                small
                            >
                                <span >
                                    {{ item.id }}
                                </span>
                                                                
                            </v-chip>
                        </template>

                        <template
                            v-slot:item=data
                        > 
                            {{ data.item.id}} {{ data.item.detail }}
                        </template> 
                    </v-select>
                </v-card>
            </v-card>
        </v-row>    

        <v-row class="mx-3 mt-1">
                        
            <v-btn                        
                outlined
                color="primary"                        
                @click="searchStudent"
                small
                width="165"
                class="mr-1"                        
            >
                Search Student
            </v-btn>                
            
            <v-btn                
                color="primary"                
                small
                @click="nextClass"
                width="165"
                depressed
            >
                Next Class
            </v-btn>
            
        </v-row>

        <v-row
            class="mt-2 mx-3"
        >
            <v-card
                flat
                width="334"
                class="d-flex justify-space-between"
            >
                <v-btn 
                    class="text-caption pa-2  white--text"
                    width="15"
                    height="35"                
                    color="primary"
                    @click="previousRecord"
                    :disabled="!pagination.prev_page"                
                >
                    <div class="d-flex flex-column">
                        <v-icon small>mdi-chevron-left</v-icon> 
                        <span>PREV</span>
                    </div>                                         
                </v-btn>

                <v-btn 
                    class="text-caption pa-2 white--text"
                    
                    height="35"                
                    color="primary"
                    @click="firstRecord"
                    :disabled="!pagination.prev_page"                
                >
                    <div class="d-flex flex-column">
                        <v-icon small>mdi-page-first</v-icon> 
                        <span>FIRST</span>
                    </div>                                         
                </v-btn>
                
                <v-btn 
                    class="text-caption pa-2"
                    width="15"
                    height="35"
                    outlined
                    disabled
                    color="primary"                
                    text                
                >
                    <span 
                        class="primary--text font-weight-bold"
                        style="font-size:0.6rem"
                    >
                        {{pagination.current_page}} of {{ pagination.last_page}}
                    </span>                                                        
                </v-btn>

                <v-btn 
                    class="text-caption pa-2 white--text"
                    width="15"
                    height="35"                
                    color="primary"
                    @click="lastRecord"
                    :disabled="!pagination.next_page"               
                >
                    <div class="d-flex flex-column">
                        <v-icon small>mdi-page-last</v-icon> 
                        <span>LAST</span>
                    </div>                                         
                </v-btn>
                
                <v-btn 
                    class="text-caption pa-2  white--text"
                    width="15"
                    height="35"
                    dark
                    color="primary"
                    @click="nextRecord"
                    :disabled="!pagination.next_page"
                >
                    <div class="d-flex flex-column">
                        <v-icon small>mdi-chevron-right</v-icon> 
                        <span>NEXT</span>
                    </div>                                         
                </v-btn>
            </v-card>    
        </v-row>

        <v-overlay
            absolute
            :value="overlay"
            color="secondary"
            opacity="0.6"
        >
            <v-expand-transition>
                <v-card
                    max-width="600"
                    class="mx-auto pa-6"
                    transition="scroll-y-transition"
                    v-show="expand"
                    light
                >
                    <v-card-title
                        class="mb-6"
                    >
                        <v-text-field
                            v-model="search"
                            label="Search for Student Record"
                            append-icon="mdi-magnify"
                            class="mx-4"
                            hide-details
                            single-line
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="table1Records"
                        :search="search"
                        fixed-header
                        height="40vh"
                        :options="options"
                        dense
                    >
                        <template
                            v-slot:[`item.actions`]="{ item }"
                        >
                            <v-btn
                                text
                                color="primary"
                                @click="viewRecord(item)"
                            >
                                View Record
                            </v-btn>
                        </template>
                    </v-data-table>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            outlined
                            @click="cancelSearch"
                        >
                            Cancel Search
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>
        </v-overlay>        

    </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    created(){
        this.initialize();
    },
    computed: {
        ...mapGetters({
            studentRecord: 'termReports/getSelectedStudentRecord',            
            academicYear: 'termReports/getAcademicYear',
            currentTerm: 'termReports/getCurrentTerm',
            formTeacherClass: 'termReports/getFormTeacherClass',
            codedComments: 'termReports/getCodedComments',
            table1Records: 'termReports/getTable1Records',
            pagination: 'termReports/getPagination',
            formClasses: 'termReports/getFormClassesList',
            admin: 'auth/getAdmin',
            activities: 'termReports/getActivities',
        }),

        studentName(){
            return this.studentRecord.first_name + ' ' + this.studentRecord.last_name;
        },
        classId(){
            return this.studentRecord.class_id;
        },
        recordSummary(){
            return [
                {title: 'Student\'s ID', model: 'student_id', select: false, disabled: true},
                {title: 'Possible Attendance', model: 'possible_attendance', select: false, disabled: true},
                {title: 'Half Days Absent', model: 'times_absent', select: false, disabled: false, attendance: true},
                {title: 'Times Late', model: 'times_late', select: false, disabled: false, attendance: true},
                {title: 'Activity 1', model: 'dcomments1', select: true, disabled: false, coCurricular: true},
                {title: 'Activity 2', model: 'dcomments2', select: true, disabled: false, coCurricular: true},
                {title: 'Activity 3', model: 'dcomments3', select: true, disabled: false, coCurricular: true},
                {title: 'Activity 4', model: 'dcomments4', select: true, disabled: false, coCurricular: true},
                {title: 'Activity 5', model: 'dcomments5', select: true, disabled: false, coCurricular: true},
                {title: 'Course', model: 'mcourse', text: true, disabled: false, ethics: true},
                {title: 'Exam', model: 'mexam', text: true, disabled: false, ethics: true},
                {title: 'Comment', model: 'mcomm', select: true, disabled: false, ethics: true},
            ]
        },

        attendance () {
            return this.recordSummary.filter(value => 
                value.attendance
            )
        },

        coCurricular () {
            return this.recordSummary.filter(value =>
                value.coCurricular
            )
        },

        ethics () {
            return this.recordSummary.filter(value => 
                value.ethics
            )
        },

        termName(){
            let termName = '';
            if(this.currentTerm == 1) termName =  'One';
            if(this.currentTerm == 2) termName = 'Two';
            if(this.currentTerm == 3) termName = 'Three'
            return termName
        }
    },
    watch: {
        studentRecord: {
            handler(){
                if(this.formTeacherClass != this.studentRecord.class_id && !this.admin){
                    // console.log('Not the form Teacher');
                    this.disabled = true;
                    this.recordSummary.forEach(element => {
                        element.disabled = true;
                    })
                }
                else{
                    this.recordSummary.forEach(element => {
                        element.disabled = false;
                    })
                }
                
            }
        }
    },
    data: () => ({
        academicPeriod: [
            { title: 'Academic Year', value: '' },
            { title: 'Term', value: '' },
        ],

        grades: [
            {grade: 'A', title: 'Excellent'},
            {grade: 'B', title: 'Very Good'},
            {grade: 'C', title: 'Good'},
            {grade: 'D', title: 'Fair'},
            {grade: 'E', title: 'Unsatisfactory'},
        ],        
        headers: [
            { text: 'Student ID', align: 'center', value: 'student_id', width: '75' },
            { text: 'First Name', align: 'left', value: 'first_name', width: '100' },
            { text: 'Last Name', align: 'left', value: 'last_name', width: '100' },
            { text: '', align: 'center', value: 'actions', sortable: false, width: '150' },
        ],
        options: {
            itemsPerPage: -1,
        },        
        disabled: false,        
        expand: false,
        overlay: false,
        search: '',
        width: 250,
        value: ' ', 
        rules: [],
        activities1: [],   
        activities2: [],   
        activities3: [],   
        activities4: [],   
        activities5: [],   
    }),
    methods: {
        ...mapActions({ 
            postTable1Record: 'termReports/postTable1Record',
            getTable2Records: 'termReports/getTable2Records', 
            setErrorResponse: 'termReports/setErrorResponse',            
        }),        
        ...mapMutations({
            setCommentSheet: 'termReports/setCommentSheet',         
            setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
            setSaved: 'termReports/setEditViewTermReportsSaved',
            setOverlay: 'termReports/setEditViewTermReportsOverlay',
            setPagination: 'termReports/setPagination',           
            setLoading: 'termReports/setEditViewTermReportsLoading',
            setSelectedStudentRecord: 'termReports/setSelectedStudentRecord',
            setTable2Records: 'termReports/setEditViewTermReportsTable2Records',
            setStudentSubjects: 'termReports/setEditViewTermReportsStudentSubjects', 
            setExpand: 'termReports/setEditViewTermReportsExpand',
            setBottomSheetModel: 'termReports/setBottomSheetModel',
            setBottomSheetTitle: 'termReports/setBottomSheetTitle',              
        }),
        setRecordSummary(){
            //
        },
        async updateRecord(){
            console.log('updating...');
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);
            //console.log(this.studentRecord);
            try{
                let response = await this.postTable1Record();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
                console.log(response);
            } catch (error) {
                console.log(error);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                this.setErrorResponse(error);
            }
            
        },

        initialize(){            
            this.academicPeriod[0].value = this.academicYear;
            this.academicPeriod[1].value = this.termName;
            // console.log(this.activities);
            this.activities1 = [...this.activities];            
            this.activities2 = [...this.activities];            
            this.activities3 = [...this.activities];            
            this.activities4 = [...this.activities];            
            this.activities5 = [...this.activities];
        },

        searchStudent(){
            this.overlay = true;
            setTimeout(() => {
                this.expand = true;
            })
        },

        viewRecord(item){
            //console.log(item);
            this.expand = false;
            setTimeout(() => {
                this.overlay = false;
            }, 600);
            let recordIndex = this.table1Records.indexOf(item);
            this.recordNavigation(++recordIndex);
        },

        cancelSearch(){
            this.expand = false;
            setTimeout(() => {
                this.overlay = false;
            }, 600);
        },

        async recordNavigation(pagination){            
            this.setOverlay(true);
            this.setLoading(true);
            
            this.makePagination(pagination);
            this.setSelectedStudentRecord(this.table1Records[--pagination]);
            try {
                const { data } = await this.getTable2Records();
                this.setTable2Records(data.table2_records);
                this.setStudentSubjects(data.student_subjects);                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }            
            this.setOverlay(false);
            this.setLoading(false);            
        },

        makePagination(data){
            let prev_page = data;
            let current_page = data;
            let next_page = (current_page === this.pagination.last_page) ? null : ++data;
            prev_page = (current_page === 1) ? null : --prev_page;
            
            let pagination = {
                current_page: current_page,
                last_page: this.pagination.last_page,
                next_page: next_page,
                prev_page: prev_page,
            };
            this.setPagination(pagination);
        },       

        previousRecord(){
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.pagination.prev_page);
            console.log('previous');
        },

        firstRecord () {
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(1);            
        },

        lastRecord () {
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.table1Records.length);
        },

        nextRecord(){
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.pagination.next_page);
        },
        
        nextClass () {
            this.setOverlay(true);
            this.setExpand(true);
        },

        setDisabledFields () {
            this.disabled = false;
            console.log(`form teacher class: ${this.formTeacherClass}`);
            this.recordSummary.forEach(element => {
                if(                           
                    element.model != 'student_id' &&
                    element.model != 'possible_attendance'
                )
                {                            
                    element.disabled = false;
                }
                else{                            
                    element.disabled = true;
                }
            })
            
            if(
                this.formTeacherClass != this.studentRecord.class_id
                && !this.admin
            )
            {                
                this.disabled = true;                    
                this.recordSummary.forEach(element => {
                    element.disabled = true;
                })
                
            }
                
        },

        openBottomSheet(model){
            this.setBottomSheetModel(model); 
            if(model == 'comments'){
                this.setBottomSheetTitle('Form Teacher Comments');
            }
            else{
                this.setBottomSheetTitle('General Remarks: Conduct');
            }          
            if(!this.disabled) this.setCommentSheet(true);
            //console.log(`sheet: ${ this.sheet }`);
        },

        saveTeacherComment(){
            this.updateRecord();
        },

        remove (model) {
            console.log(model);
            this.studentRecord[model] = null;
            this.updateRecord();
        }
        
    }
}
</script>

<style scoped>
    ::v-deep .v-input__slot{
        min-height: 0px !important;
        display: flex !important;
        align-items: center !important;
    }

    ::v-deep .v-list--dense .v-list-item{
        min-height: 32px;
    }

     ::v-deep .v-text-field__slot textarea{
        line-height: 1.2rem;
        font-size: 0.6rem;
        margin: 5px;
    }

    ::v-deep .v-btn{
        white-space: normal;
    }

    ::v-deep .v-btn__content{
        font-size: 0.9em;
        flex: 1 1 auto;
    }

    ::v-deep .v-input__append-inner{
        margin-top: 3px;        
    }

    ::v-deep .v-select__selections{
        height: 32px;       
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 8px;
        /* align-items: flex-start; */
    }

    /* ::v-deep .v-select__selection--comma{
        margin: 0 4px 4px 0;
    } */

    ::v-deep .v-text-field__slot input{
        text-align: center;
    }

    ::v-deep .v-chip.v-size--default{
        height: 20px;
        font-size: 11px;
    }    

    ::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections{
        min-height: 8px;
    }

    ::v-deep .v-select.v-select--chips .v-select__selections{
        min-height: 8px;        
    }

    ::v-deep .v-select__slot .v-input__append-inner{
        padding-left: 0;
    }

    ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
        padding: 0 2px;
    }

    ::v-deep .v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections{
        padding-left: 4px;
    }

    ::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details{
        margin-bottom: 0px;
    }

    ::v-deep .v-card__subtitle, .v-card__text{
        line-height: 1rem;
    }

    ::v-deep .v-text-field--outlined.v-input--dense .v-label{
        left: 10px;
    }

    ::v-deep .v-select .v-chip{
        margin: 0;
    }
</style>