<template>
    <tr @click="recordSelected">
        <td>{{ markRecord.count }}</td>
        <td class="pt-1">
            {{ markRecord.name }}
        </td>
        <td >
            <v-text-field
                v-model="markRecord.course_mark"
                outlined
                dense
                :filled="disableCourseMark"
                :disabled="disableCourseMark"
                hide-details
                type="number"
                min=0
                max=100
                @keypress="numberValidation"
                @blur="recordChanged('course_mark')"
                autocomplete="off"
            ></v-text-field>
        </td>
        <td >
            <v-text-field
                v-model="markRecord.exam_mark"
                outlined
                dense
                hide-details
                type="number"
                min=0
                max=100
                @keypress="numberValidation"
                @blur="recordChanged('exam_mark')"
                :filled="disableExamMark"
                :disabled="disableExamMark"
                autocomplete="off"
            ></v-text-field>
        </td>

        <td>
           <v-select
                v-model="codedComment"
                :items="comments1"
                item-value="id"
                outlined
                dense
                hide-details
                return-object
                @change="recordChanged('codedComent')"
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                >
                    {{ data.item.id}} {{ data.item.comment }}
                </template>

            </v-select>
        </td>
        <td>
            <v-select
                v-model="codedComment1"
                :items="comments2"
                item-value="id"
                outlined
                dense
                hide-details
                return-object
                @change="recordChanged('codedComment1')"
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                >
                    {{ data.item.id}} {{ data.item.comment }}
                </template>

            </v-select>
        </td>
        <td>
            <v-tooltip
                bottom

            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        v-show="saved"
                        class="mr-2"
                    >
                        mdi-check-all
                    </v-icon>
                </template>
                <span>Saved</span>
            </v-tooltip>
            <v-tooltip
                top

            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                        v-show="error"
                    >
                        mdi-alert-circle
                    </v-icon>
                </template>
                <span>Error</span>
            </v-tooltip>
            <v-progress-circular
                indeterminate
                color="primary"
                v-show="saving"
                size="16"
                width="3"
            ></v-progress-circular>
        </td>
    </tr>


</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props:{
        studentRecord: Object,
    },
    created () {
        this.initialize();
    },
    watch: {
        codedComments: {
            deep: true,
            handler(value){
                if(value.length != 0){
                    this.setComments();
                }
            }
        },
        codedComment: {
            handler(value){
                //console.log(value);
                this.markRecord.coded_comment = value.id;
            }
        },
        codedComment1: {
            handler(value){
                //console.log(value);
                this.markRecord.coded_comment_1 = value.id;
            }
        },

    },
    data() {
        return {
            commentsConduct: [],
            commentsApplication: [],
            commentsPreparedness: [],
            comments1: [],
            comments2: [],
            markRecord: {},
            codedComment: { id: null, comment: null },
            codedComment1: { id: null, comment: null },
            saved: false,
            saving: false,
            error: false,
            disableCodedComment: false,
            disableCodedComment1: false,
            disableExamMark: false,
            disableCourseMark: false,
        }
    },
    computed: {
        ...mapGetters({
            codedComments: 'termReports/getCodedComments',
            employeeId: 'auth/getEmployeeId',
            marksEntered: 'termReports/getSubjectStudentsMarksEntered',
            lessonSelected: 'termReports/getLessonSelected',
            table2Records: 'termReports/getTable2Records',
            currentTerm: 'termReports/getCurrentTerm',
        }),

        total(){
            let formLevel = this.lessonSelected.formLevel;

            if(formLevel == 5) return 'NA';
            else if(this.currentTerm == 2){
                if(formLevel == 6) return 'NA'
            }
            else if(this.currentTerm == 3){
                if(formLevel == 3 || formLevel == 4) return 'NA'
            }
            return (this.markRecord.course_mark * 0.6 + this.markRecord.exam_mark * 0.4).toFixed(1);
        }
    },
    methods : {
        ...mapMutations({
            setUpdatedRecord: 'termReports/setUpdatedTable2Record',
            setStudentSelected: 'termReports/setMarkSheetStudentSelected',
            setMarkSheetEntered: 'termReports/setSubjectStudentsMarksEntered',
            setOverlayError: 'enterMarks/setOverlayError',
            setError: 'enterMarks/setError',
        }),

        ...mapActions({
            save: 'termReports/saveTable2Record',
        }),

        async recordChanged(model){
            //console.log(this.recordBlankCheck());
            if(!this.recordBlankCheck() && this.markValid(model)){
                console.log('record changed...');
                this.saving = true;
                this.saved = false;
                this.error = false;
                this.setUpdatedRecord(
                {
                    "student_id" : this.markRecord.student_id,
                    "year" : this.markRecord.year,
                    "term" : this.markRecord.term,
                    "subject_id" : this.markRecord.subject_id,
                    "exam_mark" : this.markRecord.exam_mark,
                    "course_mark" : this.markRecord.course_mark,
                    "coded_comment" : this.codedComment.id,
                    "coded_comment_1" : this.codedComment1.id,
                    "employee_id" : this.employeeId,
                }
            );
                let response = await this.save();
                //console.log(response);

                if(response && (response.status === 200 || response.status === 201)){
                    this.saving = false;
                    this.saved = true;
                    this.error = false;
                    //console.log(this.marksEntered);
                    //let entered = this.marksEntered;
                    //this.setMarkSheetEntered(++entered);
                }
                else{
                    this.saving = false;
                    this.error = true;
                    this.saved = false;
                }
            }
        },

        recordBlankCheck () {
            // let record = [];
            let blank = true;
            // console.log(this.markRecord);
            // record.push(this.markRecord.course_mark);
            // record.push(this.markRecord.exam_mark);
            // record.push(this.markRecord.test_1);
            // record.push(this.markRecord.test_2);
            // record.push(this.markRecord.project);
            // console.log(this.markRecord);
            Object.keys(this.markRecord).forEach(key => {
                if(
                    key == 'course_mark' ||
                    key == 'exam_mark' 
                    // key == 'coded_comment' ||
                    // key == 'coded_comment_1'
                ){
                    // console.log(`key: ${key}`)
                    if(this.markRecord[key]) blank = false;
                    // console.log(`${key}: ${this.markRecord[key]}`)
                }
            })

            if(this.codedComment.comment) blank = false;

            if(this.codedComment1.comment) blank = false;
            // record.push(this.markRecord.coded_comment);
            // record.push(this.markRecord.coded_comment_1);
            // console.log(record);
            // //console.log(this.markRecord['coded_comment']);
            // record.forEach(element => {
            //     if(element) blank = false;
            // })

            // if(this.markRecord.course_mark || this.markRecord.exam_mark || this.markRecord.coded_comment || this.markRecord.coded_comment_1){
            //     console.log('not blank');
            //     return false
            // }
            // console.log(`Blank: ${blank}`);
            return blank;
        },
        savedDataCheck(){
            if(!this.recordBlankCheck()) this.saved = true;
        },
        recordSelected(){
            //console.log('marksheetrecord: ', this.markRecord);
            // console.log("record selected");
            // let records = this.table2Records;
            //console.log(records);

            // console.log(entered);
            //console.log(records);
            this.setStudentSelected(this.markRecord.name);
        },
        setComments(){
            this.comments1.push({id: null, comment: null});
            this.comments2.push({id: null, comment: null});
            this.codedComments.forEach(record =>{
                this.comments1.push({ id: record.id, comment: record.detail });
                this.comments2.push({ id: record.id, comment: record.detail });
            });

            if(this.studentRecord.coded_comment){
                this.codedComment = { id: this.studentRecord.coded_comment };
            }
            if(this.studentRecord.coded_comment_1){
                this.codedComment1 = { id: this.studentRecord.coded_comment_1 };
            }

        },
        setDisabledFields(){
            if(this.currentTerm == 3 && this.lessonSelected.formLevel  == 3){
                this.disableExamMark = true;
            }
            else if(this.lessonSelected.formLevel > 4){
                this.disableCourseMark = true;
            }

        },
        initialize(){
            this.markRecord = {...this.studentRecord};
            //console.log(this.markRecord);
            this.savedDataCheck();
            this.setComments();
            //console.log('initializing..')
            this.setDisabledFields();
            //if(this.currentTerm == 2) this.termMarkOnly = true;
            //console.log(this.currentTerm);
        },

        numberValidation ($event) {
            if($event.keyCode < 48 || $event.keyCode > 57)
            return $event.preventDefault();
        },

        markValid (model) {
            let value = this.markRecord[model];
            let formLevel = this.lessonSelected.formLevel;
            // console.log(`Value: ${value}`);
            // console.log(`Model: ${model}`);
            // console.log(`Form: ${formLevel}`);
            // console.log(`Term: ${this.currentTerm}`);

            if(
                value &&
                model == 'course_mark' &&
                this.currentTerm == 3 &&
                formLevel == 3 &&
                value > 100
            ){
                console.log('1')
                this.markRecord[model] = null;
                this.setOverlayError(true);
                this.setError({
                    response: 'Course Mark Cannot be greater than 100.'
                })
                return false;
            }
            else if(
                value &&
                model == 'course_mark' &&
                this.currentTerm == 3 &&
                formLevel < 3 &&
                value > 30
            ){
                console.log('2')
                this.markRecord[model] = null;
                this.setOverlayError(true);
                this.setError({
                    response: 'Course Mark Cannot be greater than 30.'
                })
                return false;
            }
            else if(
                value &&
                model == 'course_mark' &&
                this.currentTerm != 3 &&
                formLevel < 4 &&
                value > 30
            ){
                console.log('3')
                this.markRecord[model] = null;
                this.setOverlayError(true);
                this.setError({
                    response: 'Course Mark Cannot be greater than 30.'
                })
                return false;
            }
            else if(
                value &&
                model == 'course_mark' &&
                formLevel == 4 &&
                value > 20

            ){
                console.log('4')
                this.markRecord[model] = null;
                this.setOverlayError(true);
                this.setError({
                    response: 'Course Mark Cannot be greater than 20.'
                })
                return false;
            }
            else if(
                value &&
                model == 'exam_mark' &&
                formLevel < 4 &&
                value > 70

            ){
                console.log('5')
                this.markRecord[model] = null;
                this.setOverlayError(true);
                this.setError({
                    response: 'Exam Mark Cannot be greater than 70.'
                })
                return false;
            }
            else if(
                value &&
                model == 'exam_mark' &&
                value > 80 &&
                formLevel == 4
            ){
                console.log('6')
                this.markRecord[model] = null;
                this.setOverlayError(true);
                this.setError({
                    response: 'Exam Mark Cannot be greater than 80.'
                })
                return false;
            }
            else if(value && model == 'exam_mark' && value > 100){
                console.log('7')
                this.markRecord[model] = null;
                this.setOverlayError(true);
                this.setError({
                    response: 'Exam Mark Cannot be greater than 100.'
                })
                return false;
            }
            console.log('8')
            return true;
        }
    }
}
</script>

<style scoped>
    /* Chrome, Safari, Edge, Opera */
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align:center
    }

    /* Firefox */
    ::v-deep input[type=number] {
    -moz-appearance: textfield;
    text-align: center;
    }
</style>