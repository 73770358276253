<template>
    <v-container class="pb-0">
        <v-data-table
            :items="table2Records"
            :loading="loading"
            :headers="headers"
            :height="height"            
            fixed-header           
            class="enter-marks-table"
            disable-pagination
            hide-default-footer                                       
        >
            <template v-slot:item="props">
                <Record
                    v-bind:studentRecord="props.item"
                />
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import Record from './EnterMarksSheetRecord';
import { mapGetters } from 'vuex';
export default {
    components: {
        Record,
    },
    created () {
        //this.initialize();
    },
    data: () =>({
        loading: false,
        height: "45vh",
        pagination: false,
        footer: false,                
        headers: [],       
    }),    
    computed:{
        ...mapGetters({            
            table2Records: 'termReports/getTable2Records',
            currentTerm: 'termReports/getCurrentTerm',
            lessonSelected: 'termReports/getLessonSelected',
        }),
    },
    watch: {
        lessonSelected: {
            handler(){
                this.initialize();
            }
        }
    },
    methods: { 
       initialize(){
            if(this.currentTerm == 3 && this.lessonSelected.formLevel == 3){
                this.headers = [
                    {text: '#', value: 'count', width: '45', sortable: false},
                    {text: 'Name', value: 'name', sortable: false,},
                    {text: 'Course (100%)', value: 'course', align: 'center', width: '100', sortable: false,},            
                    {text: 'Exam (NA)', value: 'exam', align: 'center', width: '100', sortable: false,},                    
                    {text: 'Coded Comment1', value: 'codedComment1', align: 'center', width: '110', sortable: false,},            
                    {text: 'Coded Comment2',  value: 'codedComment2', align: 'center', width: '110', sortable: false,},
                    {text: ' ', value: 'actions', align: 'center', sortable: false, width: '40'},                       
                ] 
            }
            else if(this.lessonSelected.formLevel == 4){
                // console.log('term 2 form level less than 5');
                this.headers = [
                    {text: '#', value: 'count', width: '45', sortable: false},
                    {text: 'Name', value: 'name', sortable: false,},
                    {text: 'Course (20%)', value: 'course', align: 'center', width: '100', sortable: false,},            
                    {text: 'Exam (80%)', value: 'exam', align: 'center', width: '100', sortable: false,},                    
                    {text: 'Coded Comment1', value: 'codedComment1', align: 'center', width: '110', sortable: false,},            
                    {text: 'Coded Comment2',  value: 'codedComment2', align: 'center', width: '110', sortable: false,},
                    {text: ' ', value: 'actions', align: 'center', sortable: false, width: '40'},                       
                ] 
            }
            else if(this.lessonSelected.formLevel > 4){
                // console.log('term 2 form level less than 5');
                this.headers = [
                    {text: '#', value: 'count', width: '45', sortable: false},
                    {text: 'Name', value: 'name', sortable: false,},
                    {text: 'Course (NA)', value: 'course', align: 'center', width: '100', sortable: false,},            
                    {text: 'Exam (100%)', value: 'exam', align: 'center', width: '100', sortable: false,},                    
                    {text: 'Coded Comment1', value: 'codedComment1', align: 'center', width: '110', sortable: false,},            
                    {text: 'Coded Comment2',  value: 'codedComment2', align: 'center', width: '110', sortable: false,},
                    {text: ' ', value: 'actions', align: 'center', sortable: false, width: '40'},                       
                ] 
            }
            else{
                this.headers = [
                    {text: '#', value: 'count', width: '45', sortable: false},
                    {text: 'Name', value: 'name', sortable: false},
                    {text: 'Course (30%)', value: 'course', align: 'center', width: '100', sortable: false,},            
                    {text: 'Exam (70%)', value: 'exam', align: 'center', width: '100', sortable: false,},                    
                    {text: 'Coded Comment1', value: 'codedComment1', align: 'center', width: '110', sortable: false,},            
                    {text: 'Coded Comment2',  value: 'codedComment2', align: 'center', width: '110', sortable: false,},
                    {text: ' ', value: 'actions', align: 'center', sortable: false, width: '40'},                        
                ] 
            }
        }
    }
}
</script>

<style scoped>
    .v-text-field__slot textarea{
        font-size: 13px;
    }
    
    th span{
        display: flex;
    }

    ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, 
    ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, 
    ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > td, 
    ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th, 
    ::v-deep .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, 
    ::v-deep .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
        padding: 0 22px;
    } 
</style>