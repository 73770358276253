import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import admin from './admin'
import termReports from './termReports'
import mainMenu from './mainMenu'
import enterMarks from './enterMarks'
import axios from 'axios'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URI
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('access_token')}`

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        mainMenu,
        enterMarks, 
        termReports,
        admin,
    }
})