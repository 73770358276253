<template>
    <v-card
        class="elevation-4 mx-auto"
        width="600"
    >
        <v-card-title
            class="py-2 pl-8 caption white--text font-weight-light text-uppercase primary"
        >
            <v-spacer></v-spacer>
                <span>End of Term Reports</span>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-row class="pa-4">
            <v-col>
                <v-list>
                    
                    <v-list-item-group v-model="menu">
                        <v-list-item
                            v-for="(item, i) in items"
                            :key="i"
                            @click="setMenu(item.menu)"
                        >
                            
                            <v-list-item-content>
                                <v-list-item-title 
                                    v-text="item.text"
                                    :class="fontClass"
                                ></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>
            <v-col>
                    <v-img
                    src="../assets/logo.png"
                    max-width="120"
                    contain
                    class="d-flex"
                ></v-img> 
            </v-col>
        </v-row>
    </v-card>    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    name: 'MainMenu',
    data: () => ({
        items: [
            {
                icon: 'mdi-keyboard',
                text: 'Enter Marks (Subject Teachers)',
                menu: 'enter-marks',
            },
            {
                icon: 'mdi-pencil',
                text: 'Edit/View Term Details (Form Teachers)',
                menu: 'term-details',
            }
        ],
        menu: '',  
    }),
    computed: {
        ...mapGetters({
            user: 'auth/getEmployeeSignedIn',
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
            getMarkSheetLoading: 'termReports/getMarkSheetLoading',
            getEditViewTermReportsOverlay: 'termReports/getEditViewTermReportsOverlay',            
            id: 'auth/getEmployeeId',
        }),
       
       
        cardTitleClass(){
            return 'py-2 pl-8 caption white--text font-weight-light text-uppercase primary';
        },
        fontClass(){
            return 'font-weight-normal text-uppercase primary--text ';
        }
    },
   
    methods: {
        ...mapMutations({
            mainMenu: 'termReports/setMainMenu',
            enterMarks: 'termReports/setEnterMarks',
            editViewTermReports: 'termReports/setEditViewTermReports',
            welcomeMessage: 'termReports/setWelcomeMessage',
            editViewTermReportsOverlay: 'termReports/setEditViewTermReportsOverlay',
            editViewTermReportsLoading: 'termReports/setEditViewTermReportsLoading',
            formClasses: 'termReports/setFormClasses',
            setFormTeacherClass: 'termReports/setFormTeacherClass',
            setMainMenuButton: 'termReports/setMainMenuButton',            
        }),
        ...mapActions({
            getFormClasses: 'termReports/getFormClasses',
            getFormTeacherClass: 'termReports/getAssignedFormTeacherClass',  
        }),

        setMenu (menu) {
            this.welcomeMessage(false);
            this.setMainMenuButton(true);   
            switch(menu){
                case 'enter-marks':
                    this.mainMenu(false);
                    this.enterMarks(true);
                    this.editViewTermReports(false);                                      
                    break;
                case 'term-details':
                    this.mainMenu(false);
                    this.enterMarks(false);
                    this.editViewTermReports(true);                    
                    // this.setFormClases();                    
                    break;    
            }
        },
        
    }
}
</script>